<template>
  <div class="container">
    <header class="header">
      <div class="title flex">
        <p class="hover_opacity" v-if="route.query.taskId" @click="jumpTaskManage">{{ t('159') }}/&nbsp;</p>
        <p class="hover_opacity" @click="jumpTaskRecord">{{ t('161') }}/&nbsp;</p>
        <p>{{ t('162') }}</p>
      </div>
      <div class="h3-box flex-a">
        <div class="h3 flex-sb">
          <p>{{ t('821') }}</p>
          <select
            v-model="controWay"
            class="darkSelect"
            @change="changeVideo(controWay)"
          >
            <option value="0">{{ t('822') }}</option>
            <option value="1">{{ t('823') }}</option>
            <option value="2">{{ t('824') }}</option>
          </select>
        </div>
        <div class="cesium-title" style="color: #33ddf7; margin-left: 2.4rem">
          <p>{{ t('825') }}</p>
        </div>
      </div>
    </header>
    <div class="content">
      <div class="content-left">
        <RecordLog
          v-if="visible"
          :taskStartTime="taskStartTime"
          :logZIndex="logZIndex"
          v-model:isVisible="visible"
          :recordLog="state.recordLog"
        ></RecordLog>
        <SendPicture
          @closeLog="(visible = false)"
          @showLogs="(visible = true)"
          v-if="visiblePicture"
          v-model:isVisible="visiblePicture"
          :recordId="recordId"
          :picZIndex="picZIndex"
        >
        </SendPicture>
        <video id="myVideo" muted></video>
        <div class="message-box">
          <div class="message-box-top flex-sb">
            <div class="message-title">{{ t('519') }}</div>
            <div
              class="messageNav_left"
              @mouseenter="navListShows()"
              @mouseleave="navListHide()"
            >
              {{ t('826') }}
              <el-icon><CaretBottom /></el-icon>
              <transition name="el-zoom-in-top">
                <ul class="dropdown-data-style" v-if="navListShow">
                  <li
                    class="flex-fac"
                    @click="showPic"
                  >
                    {{ t('827') }}
                  </li>
                  <li class="flex-fac" @click="showLog">{{ t('514') }}</li>
                  <!-- <li class="flex-fac">{{ t('828') }}</li> -->
                </ul>
              </transition>
            </div>
          </div>

          <div class="message-container">
            <div class="message-container-item">
              <span style="width: 13.85rem; display: block"
                >{{ t('798') }}: {{ recordData.recordId }}</span
              >
              <span style="margin-left: 10rem"
                >{{ t('829') }}sn: {{ recordData.deviceSn }}</span
              >
            </div>
            <div class="message-container-item">
              <span style="width: 13.85rem; display: block"
                >{{ t('399') }}: {{ recordData.taskName }}</span
              >
              <span style="margin-left: 10rem"
                >{{ t('423') }}: {{ recordData.username }}</span
              >
            </div>
            <div class="message-container-item">
              <span>{{ t('830') }}:&nbsp;</span>
              <span style="width: 13.85rem; display: block" class="taskStatus">
                {{ taskState(recordData.taskStatus) }}</span
              >
              <span style="margin-left: 5.4rem"
                >{{ t('424') }}: {{ recordData.endWaypoint }}</span
              >
            </div>
            <div class="message-container-item">
              <span style="width: 13.85rem; display: block"
                >{{ t('831') }}: {{ parseInt(recordData.flightTime) }}s</span
              >
              <span style="margin-left: 10rem"
                >{{ t('832') }}: {{ recordData?.flightMileage?.toFixed(2) }}m</span
              >
            </div>
            <div class="message-container-item">
              <span style="width: 13.85rem; display: block"
                >{{ t('833') }}: {{ recordData.execTime }}</span
              >
              <span style="margin-left: 10rem"
                >{{ t('834') }}: {{ recordData.endTime }}</span
              >
            </div>
            <div class="message-container-item">
              <span style="width: 13.85rem; display: block"
                >{{ t('835') }}: {{ recordData.photoNumber }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <!-- cesium区域 -->
      <div class="content-right">
        <div id="cesiumContainer"></div>
        <div class="message-top">
          <div class="common-item">
            {{ t('366') }}:
            {{
              state.secondMessage?.staWs?.temperature
                ? state.secondMessage.staWs.temperature / 10
                : "0"
            }}℃
          </div>
          <div class="common-item">
            {{ t('367') }}:
            {{
              state.secondMessage?.staWs?.humidity
                ? state.secondMessage.staWs.humidity / 10
                : "0"
            }}%
          </div>
          <div class="common-item">
            {{ t('836') }}:
            {{
              state.secondMessage?.staWs?.rainfall
                ? state.secondMessage.staWs.rainfall / 10
                : "0"
            }}
            mm/s
          </div>
          <div class="common-item">
            {{ t('368') }}:
            {{
              state.secondMessage?.staWs?.speed
                ? state.secondMessage.staWs.speed / 10
                : "0"
            }}m/s
          </div>
          <div class="common-item">
            {{ t('370') }}: {{ getDirecByDegree(state.secondMessage?.staWs?.direction) }}
          </div>
        </div>
        <div class="message-right">
          <div class="common-message flex-sb">
            <span>{{ t('837') }}:</span>
            <span>{{
              typeof state.secondMessage?.staRtk?.aircraftSatelliteCount !==
              "undefined"
                ? state.secondMessage.staRtk.aircraftSatelliteCount
                : "-"
            }}</span>
          </div>
          <div class="common-message flex-sb">
            <span>{{ t('838') }}:</span>
            <span>{{
              rtkState(state.secondMessage?.staRtk?.positioningSolution)
                ? rtkState(state.secondMessage?.staRtk?.positioningSolution)
                : "-"
            }}</span>
          </div>
          <div class="common-message flex-sb">
            <span>{{ t('73') }}:</span>
            <span>{{
              typeof state.secondMessage?.staRtk?.aircraftRtkLongitude !==
              "undefined"
                ? state.secondMessage.staRtk.aircraftRtkLongitude.toFixed(5)
                : "-"
            }}</span>
          </div>
          <div class="common-message flex-sb">
            <span>{{ t('74') }}:</span>
            <span>{{
              typeof state.secondMessage?.staRtk?.aircraftRtkLatitude !==
              "undefined"
                ? state.secondMessage.staRtk.aircraftRtkLatitude.toFixed(5)
                : "-"
            }}</span>
          </div>
          <div class="common-message flex-sb">
            <span>{{ t('839') }}:</span>
            <span>{{
              typeof state.secondMessage?.staRtk?.aircraftRtkYaw !== "undefined"
                ? state.secondMessage.staRtk.aircraftRtkYaw
                : "-"
            }}</span>
          </div>
          <div class="common-message flex-sb">
            <span>{{ t('305') }}:</span>
            <span>{{
              typeof state.secondMessage?.staGim?.gimbalPitch !== "undefined"
                ? state.secondMessage.staGim.gimbalPitch
                : "-"
            }}</span>
          </div>
          <div class="common-message flex-sb">
            <span>{{ t('840') }}:</span>
            <span>{{
              typeof state.secondMessage?.staAfBattery
                ?.aircraftBatteryChargeInPercent !== "undefined"
                ? state.secondMessage.staAfBattery
                    .aircraftBatteryChargeInPercent
                : "-"
            }}</span>
          </div>
          <div class="common-message flex-sb">
            <span>{{ t('385') }}:</span>
            <span>{{
              taskState(state.secondMessage?.staWp?.missionState)
                ? taskState(state.secondMessage.staWp.missionState)
                : "-"
            }}</span>
          </div>
          <div class="common-message flex-sb">
            <span>{{ t('841') }}:</span>
            <span
              >{{
                state.secondMessage?.staWp?.missionReachIndex
                  ? state.secondMessage.staWp.missionReachIndex
                  : "0"
              }}/{{
                state.secondMessage?.staWp?.missionUploadedIndex
                  ? state.secondMessage.staWp.missionUploadedIndex
                  : "0"
              }}</span
            >
          </div>
          <div class="common-message flex-sb">
            <span>{{ t('381') }}:</span>
            <span
              >{{
                typeof state.secondMessage?.staWp?.missionReachIndex !==
                  "undefined" &&
                typeof state.secondMessage?.staWp?.missionUploadedIndex !==
                  "undefined" &&
                state.secondMessage?.staWp?.missionReachIndex !== 0
                  ? Number(
                      parseInt(
                        (state.secondMessage.staWp.missionReachIndex /
                          state.secondMessage.staWp.missionUploadedIndex) *
                          100
                      )
                    )
                  : 0
              }}%</span
            >
          </div>
        </div>
        <div class="message-bottom">
          <div class="common-span">
            {{ t('831') }}:{{
              state.secondMessage?.staAf?.aircraftFlyInSecond
                ? state.secondMessage.staAf.aircraftFlyInSecond
                : "0"
            }}s
          </div>
          <div class="common-span">
            {{ t('463') }}:{{
              state.secondMessage?.staAf?.aircraftDistToHomePoint
                ? state.secondMessage.staAf.aircraftDistToHomePoint.toFixed(1)
                : "0"
            }}m
          </div>
          <div class="common-span">
            {{ t('461') }}:{{
              state.secondMessage?.staAf?.aircraftAltitude
                ? state.secondMessage.staAf.aircraftAltitude.toFixed(1)
                : "0"
            }}m
          </div>
          <div class="common-span">
            {{ t('462') }}:{{
              state.secondMessage?.staAf?.relativeAltitude
                ? state.secondMessage.staAf.relativeAltitude.toFixed(1)
                : "0"
            }}m
          </div>
          <div class="common-span">
            {{ t('465') }}:{{
              state.secondMessage?.staAf?.aircraftVSpeed
                ? state.secondMessage.staAf.aircraftVSpeed.toFixed(1)
                : "0"
            }}m/s
          </div>
          <div class="common-span">
            {{ t('464') }}:{{
              state.secondMessage?.staAf?.aircraftHSpeed
                ? state.secondMessage.staAf.aircraftHSpeed.toFixed(1)
                : "0"
            }}m/s
          </div>
        </div>
      </div>
      <!-- cesium区域结束 -->

      <!-- 进度条底部区域 -->
      <div class="content-footer">
        <el-icon
          class="ele-icon hover_opacity"
          @click="videoPlay"
          v-if="playOrPause"
        >
          <VideoPlay />
        </el-icon>
        <el-icon class="ele-icon hover_opacity" @click="videoPause" v-else>
          <VideoPause />
        </el-icon>
        <el-slider
          style="width: 90.9375rem; margin-left: 10px"
          v-model="nowTime"
          :show-tooltip="false"
          @input="sliderInput"
          :max="sliderTime"
          @change="sliderChange"
          @click="sliderChange(nowTime)"
        />
        <div class="time-message">
          <span style="color: #0c92ea">{{ showTime }} /</span>
          <span>&nbsp;{{ endTime }}</span>
        </div>
      </div>
      <!-- 进度条底部区域结束 -->
    </div>
  </div>
</template>

<script setup>
import { t } from '../../../languages';
import { onMounted, ref, onBeforeUnmount, nextTick, reactive } from "vue";
import cesiumRecord from "./components/cesiumRecord";
import { parseTime, getJsonData } from "@/utils/utilFun";
import { initVideo } from "@/utils/flv";
import { useRouter, useRoute } from "vue-router";
import taskManage from "@/network/task";
import RecordLog from "./components/recordLog.vue";
import SendPicture from "./components/sendPicture.vue";

let myVideo; //video的dom
const showTime = ref(0); //进度条开始时间
const endTime = ref(0); //进度条结束时间
const timeShow = ref(true);
const maxTime = ref(); //  视频总时长(秒数)

const taskStartTime = ref(0); //任务开始时间
const taskEndTime = ref(); //任务结束时间
const viedoStartTime = ref(); //视频开始时间
const sliderTime = ref(0); //滑动条的时长秒数
const controWay = ref(0); //el-select切换标志

const visible = ref(false); //操作日志
const visiblePicture = ref(false); //回传图片

const { initEarth, destoryEearth, drawLineH, positionAirLine, clearAirLine } =
  cesiumRecord();

const textData = ref([]);

onMounted(() => {
  initEarth("cesiumContainer"); //初始化地球
  myVideo = document.getElementById("myVideo");
  myVideo.addEventListener("canplay", function () {
    setTimeout(() => {
      maxTime.value = this.duration; //视频时长
      console.log(maxTime.value);
    }, 2000);
  });
  console.log(myVideo);
});

//退出销毁cesium
onBeforeUnmount(() => {
  destoryEearth();
  clearInterval(timeFlag.value);
});

const playOrPause = ref(true); //播放暂停显示的变量
//进度条开始事件
const timeFlag = ref(null);
const nowTime = ref(0); //存放当前年月日时间
function videoPlay() {
  clearInterval(timeFlag.value);
  timeFlag.value = setInterval(() => {
    nowTime.value = nowTime.value + 1;
    showTime.value = parseTime(nowTime.value + taskStartTime.value);
    state.secondMessage = state.recordMessage[
      nowTime.value + taskStartTime.value
    ]
      ? state.recordMessage[nowTime.value + taskStartTime.value]
      : state.secondMessage;
    console.log(state.secondMessage);
    //大于等于视频开始时间就播放video
    if (
      nowTime.value + taskStartTime.value >= viedoStartTime.value &&
      nowTime.value < maxTime.value
    ) {
      myVideo.play();
    }
    //等于第一个点的时间开始动画
    if (nowTime.value + taskStartTime.value == textData.value[0].pointTime) {
      drawLineH(textData.value, true);
    }
    // 播放结束暂停计时,清空计时器
    if (nowTime.value + taskStartTime.value == taskEndTime.value) {
      clearInterval(timeFlag.value);
      playOrPause.value = true;
    }
  }, 1000);
  playOrPause.value = false;
  //如果开始的时间在第一个点和最后一个点之间的时间范围内,开始动画并传当前的时间 nowTime.value + taskStartTime.value.value
  if (
    nowTime.value + taskStartTime.value >= textData.value[0].pointTime &&
    nowTime.value + taskStartTime.value <= textData.value.at(-1).pointTime
  ) {
    clearAirLine();
    drawLineH(textData.value, true, nowTime.value + taskStartTime.value);
  }
}

//video暂停事件
function videoPause() {
  clearInterval(timeFlag.value);
  myVideo.pause();
  playOrPause.value = true;
  //如果暂停的时间超过最后一个点的时间,则传最后一个点的时间并停止画面
  if (nowTime.value + taskStartTime.value >= textData.value.at(-1).pointTime) {
    clearAirLine();
    drawLineH(textData.value, false, textData.value.at(-1).pointTime);
  }
  //如果暂停的时间小于第一个点的时间,则传第一个点的时间并停止画面
  else if (nowTime.value + taskStartTime.value <= textData.value[0].pointTime) {
    clearAirLine();
    drawLineH(textData.value, false, textData.value[0].pointTime);
  }
  //其余的则停止画面并传当前的时间 nowTime.value + taskStartTime.value.value
  else {
    clearAirLine();
    drawLineH(textData.value, false, nowTime.value + taskStartTime.value);
  }
}

function sliderInput(val) {
  showTime.value = parseTime(val + taskStartTime.value); //底下的时间跟着进度条拖动而改变
}

function sliderChange(val) {
  console.log(val);
  state.secondMessage = state.recordMessage[val + taskStartTime.value]
    ? state.recordMessage[val + taskStartTime.value]
    : state.secondMessage;
  //拖动的时候如果是在视频所在时间的范围内,执行逻辑
  if (
    0 <= taskStartTime.value + val - viedoStartTime.value &&
    taskStartTime.value + val - viedoStartTime.value <= maxTime.value
  ) {
    //拖动的时候是播放状态就让他播,反之就停在那个位置
    if (!playOrPause.value) {
      myVideo.play();
      myVideo.currentTime = taskStartTime.value + val - viedoStartTime.value; //视频跳转到该位置
      console.log("bofang");
    } else {
      myVideo.pause();
      myVideo.currentTime = taskStartTime.value + val - viedoStartTime.value; //视频跳转到该位置
      console.log(123);
    }
  }
  //拖动的时候如果不在视频所在时间的范围内,执行逻辑
  else {
    //拖动的时间超出视频的时间,停留在最后一个画面
    if (taskStartTime.value + val - viedoStartTime.value >= maxTime.value) {
      myVideo.currentTime = parseInt(maxTime.value); //视频跳转到该位置
      console.log(parseInt(maxTime.value));
      myVideo.pause();
      console.log(t("842"));
    }
    //反之停留在第一个画面
    else {
      myVideo.currentTime = 0;
    }
  }
  //视频拖动逻辑end

  //地图拖动逻辑
  if (
    val + taskStartTime.value >= textData.value[0].pointTime &&
    val + taskStartTime.value <= textData.value.at(-1).pointTime
  ) {
    if (!playOrPause.value) {
      clearAirLine();
      drawLineH(textData.value, true, val + taskStartTime.value);
      console.log(val + taskStartTime.value);
    } else {
      clearAirLine();
      drawLineH(textData.value, false, val + taskStartTime.value);
    }
  } else if (val + taskStartTime.value > textData.value.at(-1).pointTime) {
    clearAirLine();
    drawLineH(textData.value, true, textData.value.at(-1).pointTime);
  } else {
    clearAirLine();
    drawLineH(textData.value, false);
  }
}

//下拉菜单
const navListShow = ref(false);
//鼠标移入移出展示隐藏下拉菜单
const navListShows = () => {
  navListShow.value = true;
};
const navListHide = () => {
  navListShow.value = false;
};

//跳转回任务管理
const router = useRouter();
function jumpTaskManage() {
  router.push("/taskManagement");
}

//跳转回任务记录
const route = useRoute();
function jumpTaskRecord() {
  if (!route.query.taskId) {
    console.log('no has query');
    router.push({
    path: "/allTaskRecord",
    });
  }else{
    console.log('has query');
    router.push({
      path: "/taskRecord",
      query: {
        taskId: route.query.taskId,
      },
    });
  }
}
let recordId = route.query.recordId;
//获取左下角面板信息
const recordData = ref({});
const changecolor = ref("");
async function getTaskRecordMessage() {
  const res = await taskManage.getTaskRecordMessage(recordId);
  recordData.value = res.resultData;
  switch (res.resultData.taskStatus) {
    case "FINISH":
      changecolor.value = "#3EEB27";
      break;
    case "FAIL":
      changecolor.value = "#E60012 ";
      break;
    case "CANCEL":
      changecolor.value = "#FFFFFF";
      break;
  }
}
getTaskRecordMessage();

const state = reactive({
  recordMessage: {}, //任务的全部数据
  secondMessage: {}, //对应时间帧的全部数据
  recordLog: [], //任务的全部日志
  videoUrl: [], //任务的全部jsonurl
  airUrl: "",
  outUrl: "",
  inUrl: "",
});

//获取执行记录的json路径
async function getTaskRecordDetails() {
  const res = await taskManage.getTaskRecordDetails({
    recordId: recordId,
  });
  // console.log(res.resultData);
  let timeUrl = res.resultData.find((item) => item.fileType == 1);
  // console.log(timeUrl.fileUrl.time);
  let trajectoryUrl = res.resultData.find((item) => item.fileType == 2);
  // console.log(trajectoryUrl.fileUrl.trajectory);
  let dataUrl = res.resultData.find((item) => item.fileType == 3);
  // console.log(dataUrl.fileUrl.data);
  state.viedoUrl = res.resultData.filter((item) => item.fileType == 4);
  // console.log(state.viedoUrl);
  state.viedoUrl.forEach((item) => {
    if (Object.keys(item.fileUrl)[0] === "DRONE_PUSH") {
      state.airUrl = item.fileUrl.DRONE_PUSH;
      // console.log(state.airUrl);
    } else if (Object.keys(item.fileUrl)[0] === "OUT_PUSH") {
      state.outUrl = item.fileUrl.OUT_PUSH;
      // console.log(state.outUrl);
    } else if (Object.keys(item.fileUrl)[0] === "IN_PUSH") {
      state.inUrl = item.fileUrl.IN_PUSH;
      // console.log(state.inUrl);
    }
  });
  let logUrl = res.resultData.find((item) => item.fileType == 5);
  // console.log(logUrl.fileUrl.log);

  const resTime = await getJsonData(timeUrl.fileUrl.time);

  console.log(resTime); //任务开始和结束时间的数据
  taskStartTime.value = resTime?.startTime; //任务开始时间
  viedoStartTime.value = resTime.startTime; //视频开始时间
  taskEndTime.value = resTime.endTime; //任务结束时间
  sliderTime.value = taskEndTime.value - taskStartTime.value; //任务时间长度(单位为秒)
  textData.value = await getJsonData(trajectoryUrl.fileUrl.trajectory); //任务所有航点数据
  console.log(textData.value);
  showTime.value = parseTime(taskStartTime.value); //返回格式化的时间
  endTime.value = parseTime(taskEndTime.value);
  state.recordMessage = await getJsonData(dataUrl.fileUrl.data);
  state.recordLog = await getJsonData(logUrl.fileUrl.log);
  console.log(state.recordLog);
  nextTick(() => {
    drawLineH(textData.value, false);
    positionAirLine(); //定位到航线实体所在位置
    initVideo(myVideo, state.airUrl, 'flv');
  });
}

getTaskRecordDetails();

function changeVideo(val) {
  switch (val) {
    case "0":
      initVideo(myVideo, state.airUrl, 'flv');
      myVideo.currentTime = nowTime.value;
      break;
    case "1":
      initVideo(myVideo, state.outUrl, 'flv');
      myVideo.currentTime = nowTime.value;
      break;
    case "2":
      initVideo(myVideo, state.inUrl, 'flv');
      myVideo.currentTime = nowTime.value;
      break;
  }
}

/* 根据度数返回风向 */
function getDirecByDegree(degree) {
  degree = degree / 10;
  if (degree === 0 || degree === 360) {
    return t("111");
  } else if (degree > 0 && degree < 90) {
    return t("112");
  } else if (degree === 90) {
    return t("113");
  } else if (degree > 90 && degree < 180) {
    return t("114");
  } else if (degree === 180) {
    return t("115");
  } else if (degree > 180 && degree < 270) {
    return t("116");
  } else if (degree === 270) {
    return t("117");
  } else if (degree > 270 && degree < 360) {
    return t("118");
  } else {
    return "-";
  }
}

// rtk状态
const rtkgy = {
  NONE: t("76"),
  SINGLE_POINT: t("77"),
  FLOAT: t("78"),
  FIXED_POINT: t("79"),
  UNKNOWN: t("80"),
};
const rtkState = function (type) {
  return rtkgy[type];
};

// 飞行记录任务状态
const strategy = {
  READY_TO_ISSUE: t("571"),
  UPLOADING: t("572"),
  READY_TO_EXECUTE: t("573"),
  START: t("563"),
  EXECUTING: t("88"),
  ROUTE_FINISH: t("574"),
  PHOTO_UPLOAD: t("575"),
  FINISH: t("576"),
  CANCEL: t("217"),
  EXECUTION_PAUSED: t("430"),
  TERMINATION: t("577"),
  FAIL: t("578"),
  WAIT_RETRY: t("579"),
  UNKNOWN: t("80"),
  TIME_OUT: t("580"),
  RECOVERING: t("121"),
};
const taskState = function (type) {
  return strategy[type];
};

const picZIndex = ref(0)
const logZIndex = ref(0)

const showPic = () => {
  visiblePicture.value = !visiblePicture.value
  picZIndex.value = 100
  logZIndex.value = 99
}
const showLog = () => {
  visible.value = !visible.value
  picZIndex.value = 99
  logZIndex.value = 100
}
</script>

<style lang='less' scoped>
.container {
  width: 100%;
  height: 100%;
  padding: 1.25rem 0.625rem;
  background-color: rgba(6, 21, 30, 1);

  .header {
    height: 5rem;
    margin-left: 2px;
    position: relative;

    .title {
      p {
        font-size: 1.5rem;
        font-family: Source Han Sans CN;
        font-weight: 600;
        color: #33ddf7;
      }
    }
    .h3-box {
      width: 100%;
      height: 2.0625rem;
      position: absolute;
      bottom: 5px;
      .h3 {
        height: 2.0625rem;
        width: 44.9%;
        p {
          font-size: 1rem;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #33ddf7;
        }
        .darkSelect {
          margin-left: 30.3%;
          width: 8.9375rem;
          padding: 4px 5px;
          background: rgba(10, 69, 114, 1);
          color: #fff;
          outline: none;
          border: none;
          // font-family: "coolBlack";

          > option {
            height: 40px;
            background: rgba(21, 80, 124, 1);
          }
        }
      }
    }
  }

  .content {
    display: flex;
    height: 92%;
    width: 100%;
    position: relative;

    &-left {
      height: 70vh;
      width: 45%;
      position: relative;

      #myVideo {
        width: 100%;
        height: 50%;
        background: #000;
      }

      .message-box {
        margin-left: 1px;
        height: 49.3%;
        width: 100%;
        position: relative;
        .message-box-top {
          height: 2.5rem;
        }

        .message-title {
          color: #33ddf7;
        }
        .messageNav_left {
          display: flex;
          width: 10.1875rem;
          height: 2rem;
          background: #0a4572;
          color: #ccc;
          text-shadow: 0px 2px 6px #091c3b;
          align-items: center;
          position: relative;
          justify-content: space-evenly;
          cursor: pointer;
          .dropdown-data-style {
            display: flex;
            flex-direction: column;
            position: absolute;
            top: 2rem;
            z-index: 99;

            li {
              width: 10.1875rem;
              height: 2.1875rem;
              color: #ccc;
              padding: 0.875rem 0.9375rem;
              text-decoration: none;
              text-shadow: 0px 2px 6px #091c3b;
              background: #0a4572;
              margin-top: 1px;
              font-size: 0.875rem;
            }
            li:hover {
              background: #4289b8;
              color: #ffffff;
            }
          }
        }

        .message-container {
          position: absolute;
          bottom: 0;
          width: 100%;
          height: calc(100% - 2.7125rem);
          border: 1px solid #33ddf7;
          padding: 0 10.25rem 0.625rem 0.625rem;

          .message-container-item {
            display: flex;
            color: #fff;
            margin-top: 1.325rem;
            .taskStatus {
              color: v-bind(changecolor);
            }
          }
        }
      }
    }

    &-right {
      position: absolute;
      width: 53%;
      right: 0;
      height: 70vh;

      #cesiumContainer {
        height: 100%;
      }

      .message-top {
        position: absolute;
        top: 2px;
        left: 6.25rem;
        right: 6.25rem;
        height: 1.875rem;
        background: rgba(8, 42, 62, 0.7);
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: space-around;
        font-family: "coolBlack";
        border-radius: 10px;
      }

      .message-right {
        width: 11.9375rem;
        padding: 0.625rem 0.625rem;
        position: absolute;
        border-radius: 10px;
        top: 2.5rem;
        right: 10px;
        z-index: 1;
        background: rgba(8, 42, 62, 0.7);
        font-size: 0.875rem;

        .common-message:not(:first-child) {
          margin-top: 0.9375rem;
        }
      }

      .message-bottom {
        position: absolute;
        bottom: 2px;
        left: 10px;
        right: 10px;
        height: 1.875rem;
        background: rgba(8, 42, 62, 0.7);
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: space-around;
        font-family: "coolBlack";
        border-radius: 10px;
      }
    }

    &-footer {
      position: absolute;
      bottom: 0px;
      height: 2.375rem;
      display: flex;
      width: 100%;
      align-items: center;
      background: #012438;

      .ele-icon {
        font-size: 1.875rem;
        margin-right: 0.625rem;
      }
      .time-message {
        position: absolute;
        right: 3.25rem;
      }
    }
  }
}
</style>