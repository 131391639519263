import { t } from '../../../../languages';
import ufi3d from "@/utils/ufi-cesium";
import { inject, ref } from "vue";
import { chinaTime } from "@/utils/utilFun";

const cesiumRecord = () => {
  let viewer = null;
  let cesiumDom = "";
  let handler = null;
  const Cesium = inject("Cesium");

  /**
   * 初始化地球
   * @param {* cesium dom的id} el --string
   */
  function initEarth(el) {
    cesiumDom = el;
    Cesium.Camera.DEFAULT_VIEW_RECTANGLE = Cesium.Rectangle.fromDegrees(
      90.0, // 东
      30.0, // 南
      110.0, // 西
      45.0 // 北
    );
    const tiandituConSess = localStorage.getItem("tiandituCon");
    viewer = ufi3d.initCesium(el, {
      // 地形
      terrainProvider: setTerrain()
    });
    /* 设置地形 */
    function setTerrain() {
      const mars3dTerrain = localStorage.getItem('mars3dTerrain')
      const terrainUrl = (mars3dTerrain == 'true') ? 'https://data.mars3d.cn/terrain' : window.g.terrainUrl
      return  new Cesium.CesiumTerrainProvider({
          url: terrainUrl
      })
    }
    
    // 需要带上，不然会加载默认地图
    viewer.imageryLayers.removeAll();
    if (tiandituConSess === "true") {
      // 天地图
      const subdomains = ["0", "1", "2", "3", "4", "5", "6", "7"];
      const layers = viewer.imageryLayers;
      layers.addImageryProvider(
        new Cesium.WebMapTileServiceImageryProvider({
          //影像底图
          url:
            "https://t{s}.tianditu.gov.cn/img_w/wmts?service=wmts&request=GetTile&version=1.0.0&LAYER=img&tileMatrixSet=w&TileMatrix={TileMatrix}&TileRow={TileRow}&TileCol={TileCol}&style=default&format=tiles&tk=" +
            "47421dd0051ada3957f279b484932789",
          subdomains: subdomains, //URL模板中用于{s}占位符的子域。如果该参数是单个字符串，则字符串中的每个字符都是一个子域。如果它是一个数组，数组中的每个元素都是一个子域
          layer: "tdtImgLayer",
          style: "default",
          format: "image/jpeg",
          tileMatrixSetID: "GoogleMapsCompatible", //使用谷歌的瓦片切片方式
          maximumLevel: 18,
          show: true,
        })
      );
      layers.addImageryProvider(
        new Cesium.WebMapTileServiceImageryProvider({
          //影像注记
          url:
            "https://t{s}.tianditu.gov.cn/cia_w/wmts?service=wmts&request=GetTile&version=1.0.0&LAYER=cia&tileMatrixSet=w&TileMatrix={TileMatrix}&TileRow={TileRow}&TileCol={TileCol}&style=default.jpg&tk=" +
            "47421dd0051ada3957f279b484932789",
          subdomains: subdomains,
          layer: "tdtCiaLayer",
          style: "default",
          format: "image/jpeg",
          tileMatrixSetID: "GoogleMapsCompatible",
          maximumLevel: 18,
          show: true,
        })
      );
    } else {
      // 谷歌地图
      const layers = viewer.imageryLayers;
      layers.addImageryProvider(
        new Cesium.UrlTemplateImageryProvider({
          url: window.g.satelliteMapUrl + "/{z}/{x}/{y}.png",
          tilingScheme: new Cesium.WebMercatorTilingScheme(),
        })
      );
    }
  }

  /* 销毁地球 */
  function destoryEearth() {
    viewer.entities.removeAll();
    viewer = null;
    document.querySelector("#" + cesiumDom).innerHTML = "";
  }

  let trackLine; //轨迹对象
  let hasTrackline = false; //是否有轨迹

  cesiumRecord.TrackLine = class TrackLine {
    constructor(viewer) {
      this.viewer = viewer;
    }

    addTrackLine(
      positions,
      modalUrl,
      lineType,
      lineColor,
      lineWidth,
      flag,
      nowTime
    ) {
      // console.log(positions);
      // console.log(nowTime);
      let startTime = Cesium.JulianDate.fromDate(
        chinaTime(positions[0].pointTime)
      );
      let stopTime = Cesium.JulianDate.fromDate(
        chinaTime(positions[positions.length - 1].pointTime)
      );
      if (nowTime) {
        let planeTime = Cesium.JulianDate.fromDate(chinaTime(nowTime));
        this.viewer.clock.currentTime = planeTime.clone();
      } else {
        this.viewer.clock.currentTime = startTime.clone();
      }
      // console.log(chinaTime(positions[0].pointTime));
      // console.log(chinaTime(positions[positions.length - 1].pointTime));
      // console.log(startTime);
      // console.log(stopTime);
      this.viewer.clock.startTime = startTime.clone();
      this.viewer.clock.stopTime = stopTime.clone();

      // this.viewer.timeline.zoomTo(startTime, stopTime);
      this.startTime = startTime.clone();
      this.stopTime = stopTime.clone();
      this.viewer.clock.shouldAnimate = true;
      this.viewer.clock.clockRange = Cesium.ClockRange.CLAMPED;
      const linePositions = this.computeCirclularFlight(positions);

      const material = this.switchMaterial(lineType, lineColor);
      this.airplaneEntity = this.viewer.entities.add({
        position: this.property,
        orientation: Cesium.Transforms.headingPitchRollQuaternion(
          Cesium.Cartesian3.fromDegrees(
            positions[0].longitude,
            positions[0].latitude
          ),
          new Cesium.HeadingPitchRoll(
            Cesium.Math.toRadians(0), // 设置这个属性即可（顺时针旋转的角度值）
            Cesium.Math.toRadians(0),
            Cesium.Math.toRadians(0)
          )
        ),
        //控制镜头在模型的前后，左右，上下
        viewFrom: new Cesium.Cartesian3(0, 10, 20),
        model: {
          uri: modalUrl ? modalUrl : "",
          clampAnimations: true,
          scale: 0.1,
          minimumPixelSize: 50,
          maximumScale: 60,
          // color: Cesium.Color.fromCssColorString("#FF0000")
        },
        path: {
          show: true,
          leadTime: 0, //指定要显示的对象前面的秒数
          trailTime: 1000000000, //该属性指定要显示的对象后面的秒数。越小轨迹越短
          width: lineWidth,
          resolution: 1,
          material: material,
        },
      });
      this.viewer.clock.shouldAnimate = flag;
      this.viewer.trackedEntity = this.airplaneEntity;
      if (positions.length > 0) {
        return true;
      } else {
        return false;
      }
    }

    /* 返回航线实体 */
    getLineEntity() {
      return this.airplaneEntity;
    }

    clearTrackLine() {
      // 清空轨迹
      this.viewer.entities.remove(this.airplaneEntity);
      console.log("我被清空啦");
    }

    //获取飞行的动画点位
    computeCirclularFlight(pArr) {
      this.property = new Cesium.SampledPositionProperty();
      const positions = [];
      for (let i = 0; i < pArr.length; i++) {
        const pItem = pArr[i];
        const position = Cesium.Cartesian3.fromDegrees(
          pItem.longitude,
          pItem.latitude,
          pItem.altitude
        );
        const originTime = new Date(Number(pItem.pointTime) * 1000);
        // console.log('原始时间', originTime)
        const time = Cesium.JulianDate.fromDate(originTime);
        // 0时刻开始，1700时刻结束
        // 0时刻开始，10
        positions.push(position);
        this.property.addSample(time, position);
      }
      return positions;
    }

    switchMaterial(type, color) {
      const materialColor = Cesium.Color.fromCssColorString(color);
      let material = undefined;
      switch (type) {
        // 发光材质
        case "glow":
          material = new Cesium.PolylineGlowMaterialProperty({
            glowPower: 1, //荧光效果，线周边发亮
            taperPower: 0.2, //控制一头线粗 一头线细，为1时线的两头粗细一样
            color: materialColor,
          });
          break;
        // 边框线
        case "outline":
          material = new Cesium.PolylineOutlineMaterialProperty({
            color: Cesium.Color.WHITE,
            outlineColor: materialColor,
            outlineWidth: 1,
          });
          break;
        // 斑马纹
        case "dash":
          material = new Cesium.PolylineDashMaterialProperty({
            color: materialColor,
            gapColor: Cesium.Color.WHITE,
            dashLength: 50,
          });
          break;
        // 尾部箭头
        case "arrow":
          material = new Cesium.PolylineArrowMaterialProperty(materialColor);
          break;
        case "":
          material = materialColor;
          break;
        default:
          material = materialColor;
          break;
      }
      return material;
    }
  };

  /* 画历史轨迹 */
  function drawLineH(trajectoryData, flag, currentTime) {
    trackLine = new cesiumRecord.TrackLine(viewer);
    if (trajectoryData instanceof Array && trajectoryData.length > 0) {
      hasTrackline = trackLine.addTrackLine(
        trajectoryData,
        "public/model/drone.glb",
        "",
        "#00E4FF",
        4,
        flag,
        currentTime
      );
    }
  }
  /*定位到轨迹位置*/
  function positionAirLine() {
    const lineEntity = trackLine.getLineEntity();
    // console.log(lineEntity);
    viewer.flyTo(lineEntity, {
      offset: new Cesium.HeadingPitchRange(0, Cesium.Math.toRadians(-45), 400),
      duration: 3,
    });
  }

  //清空轨迹的函数
  function clearAirLine() {
    trackLine.clearTrackLine();
  }

  return {
    initEarth,
    destoryEearth,
    drawLineH,
    positionAirLine,
    clearAirLine,
    cesiumRecord,
  };
};

export default cesiumRecord;
