<template>
  <drag-dialog
    :style="{zIndex:logZIndex}"
    v-model:visible="dialogVisible"
    :right="-21.95"
    :top="3.8"
    :width="21.25"
    :height="30"
    :title="t('514')"
  >
    <div class="log_box">
      <ul class="log_ul" id="log_ul">
        <li v-for="(item, index) in logList" :key="index">
          <h5 v-if="item.date">{{ item.date }}</h5>
          <dl class="wrap_dl">
            <dt v-if="item.operateTime">{{ parseTime(item.operateTime) }}</dt>
            <dd>
              <b v-if="item.operateStatus === true" class="success_info"
                >{{ t('964') }}</b
              >
              <b v-if="item.operateStatus === false" class="error_info">{{ t('965') }}</b>
              <span>{{ item.logContent }}</span>
            </dd>
          </dl>
        </li>
      </ul>
    </div>
  </drag-dialog>
</template>

<script setup>
import { t } from '../../../../languages';
import { ref, toRefs, watch } from "vue";
import { parseTimes, parseTime } from "@/utils/utilFun";
const props = defineProps({
  isVisible: {
    type: Boolean,
    default: false,
  },
  recordLog: {
    type: Array,
    default: () => {},
  },
  taskStartTime: {
    type: Number,
    default: 0,
  },
  logZIndex: {
    type: Number,
  },
});
const { isVisible, taskStartTime,logZIndex } = toRefs(props);
console.log(props.logZIndex);
/* 获取操作日志 */
const logList = ref([]);
function getLog() {
  console.log(props.recordLog);
  let abc = props.recordLog.filter((item) => {
    return item.operateTime >= taskStartTime.value * 1000;
  });
  console.log(abc);
  if (abc instanceof Array) {
    const logArr = [];
    let date = "";
    for (let i = 0; i < abc.length; i++) {
      const currDate = parseTimes(abc[i].totalOperateTime).split("-")[2];
      if (currDate !== date) {
        logArr.push({
          date: parseTimes(abc[i].totalOperateTime),
        });
      }
      date = currDate;
      logArr.push(abc[i]);
    }
    logList.value = logArr;
  }
}
getLog();

const emit = defineEmits(["update:isVisible"]);
const dialogVisible = ref(null);
dialogVisible.value = isVisible.value;
watch(dialogVisible, (newProps) => {
  dialogVisible.value = newProps;
  emit("update:isVisible", false);
});
</script>

<style scoped lang="less">
.log_box {
  width: 100%;
  height: 100%;

  .log_ul {
    height: 26.875rem;
    overflow: auto;
    padding: 1.25rem 1.375rem;

    li:not(:last-child) {
      margin-bottom: 0.4375rem;
    }

    h5 {
      text-align: center;
      font-size: 0.875rem;
      color: #36e5ff;
    }
  }
}
.log_box:hover {
  .log_ul::-webkit-scrollbar {
    width: 0.6rem;
  }
}
/* 换行dl */
.wrap_dl {
  position: relative;
  padding-left: 2.5rem;
  font-size: 0.875rem;

  dt {
    position: absolute;
    top: 0;
    left: 0;
    width: 3.75rem;
    color: #00f1fb;
  }

  dd {
    line-height: 1.4;
  }

  .success_info {
    color: #67c529;
  }

  .error_info {
    color: #e11414;
  }

  b {
    /* 解决行内元素间距问题 */
    position: relative;
    margin-right: 0.875rem;
  }
  span {
    color: #ffffff;
  }
}
</style>
